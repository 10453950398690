@charset "UTF-8";
/* ================================================================= 
 License : e-TRUST Inc.
 File name : common2.css
 Style : common
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
.mincho, #header .inner .flxR .tel span, #header .inner .flxR .tel a, #nav_global ul li a {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.shadow, .hanko-flex .hanko-table {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.bg-gra, #nav_global, .product-side h3 {
  background-image: linear-gradient(50deg, #2989cc 0%, #0049ac 100%);
  background-image: -moz-linear-gradient(0deg, #2989cc 0%, #0049ac 100%);
  background-image: -webkit-linear-gradient(0deg, #2989cc 0%, #0049ac 100%);
  background-image: -ms-linear-gradient(0deg, #2989cc 0%, #0049ac 100%);
}

.mincho, #header .inner .flxR .tel span, #header .inner .flxR .tel a, #nav_global ul li a {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.ggmap {
  position: relative;
  padding-bottom: 25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .ggmap {
    padding-bottom: 45%;
  }
}

@media only screen and (max-width: 640px) {
  .ggmap {
    padding-bottom: 40%;
  }
}

.ggmap iframe,
.ggmap object,
.ggmap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

/*================== プライバシーポリシー =================*/
.policy {
  text-align: left;
}

.policy-text {
  font-size: 18px;
}

.policy h2 {
  border-left: 4px solid #07206a;
  color: #333;
  padding-left: 8px;
  font-size: 18px;
  letter-spacing: 0.1em;
  font-weight: normal;
}

.policy-trriger {
  color: #07206a;
  cursor: pointer;
  text-decoration: underline;
}

.policy-wrap {
  display: none;
  position: fixed;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  top: 0;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .policy-wrap {
    left: 0;
  }
}

.policy-wrap > div {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  -ms-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  transition-duration: 0.6s;
  opacity: 0;
  position: relative;
  padding: 20px;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #07206a;
  height: 60vh;
  -ms-overflow-y: scroll;
  overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
  .policy-wrap > div {
    width: 95%;
  }
}

.policy-wrap > div a {
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 2.4rem;
  font-weight: bold;
}

.policy {
  text-align: left;
}

.policy-text {
  font-size: 18px;
}

.policy-wrap h3 {
  margin-top: 16px;
  border-left: 4px solid #07206a;
  color: #333;
  padding-left: 8px;
  font-size: 18px;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.policy p {
  margin: 10px 0 25px;
}

.policy-wrap {
  opacity: 0;
  transition-duration: 1s;
}

.policy-wrap.active {
  animation: fade-in 0.5s;
  display: flex;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
}

.policy-wrap.active > div {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.8;
  font-weight: 500;
  letter-spacing: 0.2em;
  color: #333;
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 15px;
}

.inner {
  margin: 0 auto;
  max-width: 1000px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho, #header .inner .flxR .tel span, #header .inner .flxR .tel a, #nav_global ul li a {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

.flex-temp01 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media only screen and (max-width: 768px) {
  .flex-temp01 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.flex-temp01 > div {
  width: 48%;
}

@media only screen and (max-width: 768px) {
  .flex-temp01 > div {
    width: 100% !important;
  }
}

html {
  font-size: 62.5%;
}

.inner1 {
  max-width: 1000px;
  margin: 0 auto;
}

.inner2 {
  max-width: 100%;
  margin: 0 auto;
}

.flow-text {
  width: 80%;
  padding: 0 0.8rem;
}

.flow-title {
  width: 88%;
}

.flow-title > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  background: #ee4245;
}

.arrow02 {
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 100px;
  /* 四角形の幅 */
  height: 48px;
  /* 四角形の高さ */
  background: #e31927;
  position: relative;
}

.arrow02:after {
  border-left: 16px solid #e31927;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: 50px;
}

.info-table {
  width: 80%;
  max-width: 800px;
}

.info-table table td {
  border: none !important;
  border-bottom: dotted #aaa 1px !important;
}

@media only screen and (max-width: 640px) {
  .info-table table td {
    border-bottom: none !important;
  }
  .info-table table td:first-child {
    padding-bottom: 0 !important;
  }
  .info-table table td:last-child {
    padding-top: 0 !important;
  }
  .info-table table tr {
    border-bottom: dotted #aaa 1px !important;
  }
}

.sozai-table .mincho, .sozai-table #header .inner .flxR .tel span, #header .inner .flxR .tel .sozai-table span, .sozai-table #header .inner .flxR .tel a, #header .inner .flxR .tel .sozai-table a, .sozai-table #nav_global ul li a, #nav_global ul li .sozai-table a {
  font-size: 2rem;
}

.sozai-table tbody tr:first-child {
  background-color: #ea9d84;
}

.sozai-table tbody tr:nth-child(n+2) td:first-child {
  background: #F1EAD8;
}

.sozai-table tbody tr:nth-child(n+2) td:last-child {
  background: #F1EAD8;
}

.hanko-flex {
  flex-wrap: wrap;
  align-items: stretch;
}

.hanko-flex .hanko-table {
  background: rgba(255, 250, 225, 0.4);
  width: 32%;
  border: #aaa solid 1px;
  padding: 1.6rem 0;
}

.hanko-flex .hanko-table tr td {
  border: none !important;
}

.hanko-flex .hanko-table tr td.mincho {
  font-size: 3.2rem;
  font-weight: 900;
  line-height: 1.2;
  padding-bottom: 0 !important;
}

.hanko-flex .hanko-table tr td.mincho small {
  margin-left: -1rem;
  font-size: 1.8rem;
  letter-spacing: 0;
}

.hanko-flex .hanko-table tr td.mincho br {
  display: none;
}

@media only screen and (max-width: 768px) {
  .hanko-flex .hanko-table {
    width: 48% !important;
  }
}

@media only screen and (max-width: 640px) {
  .hanko-flex .hanko-table {
    width: 100% !important;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

#header {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: stretch;
}

#header .inner {
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  #header .inner {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 768px) {
  #header .inner {
    padding: 58px 10px 0;
  }
}

@media only screen and (max-width: 640px) {
  #header .inner {
    display: block;
  }
}

#header .inner .flxL {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  #header .inner .flxL {
    width: 100%;
  }
}

#header .inner .flxL #siteID a {
  line-height: 1;
  display: block;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  #header .inner .flxL #siteID a {
    text-align: center;
  }
}

#header .inner .flxR {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: start;
  right: 0;
  top: 0;
  height: 100%;
}

@media only screen and (max-width: 1000px) {
  #header .inner .flxR {
    width: 100%;
  }
}

#header .inner .flxR .tel span, #header .inner .flxR .tel a {
  color: #137500;
}

#header .inner .flxR .mail {
  background: #ee8c00;
  width: 100px;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  z-index: 2;
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
}

#nav_global ul li {
  display: inline-block;
  position: relative;
}

#nav_global ul li a {
  display: inline-block;
  font-weight: 600;
  font-size: 1.6rem;
  color: #fff;
}

#nav_global ul li ul {
  display: block;
  position: absolute;
  width: 100%;
  background: rgba(231, 122, 146, 0.5);
}

#nav_global ul li ul li {
  border: 0 !important;
  width: 100% !important;
}

#nav_global ul li ul li a {
  padding: 10px 0;
}

#mainArea {
  position: relative;
  margin-bottom: 80px;
  z-index: 0;
}

@media only screen and (max-width: 768px) {
  #mainArea {
    margin-bottom: 50px;
  }
}

#mainArea img {
  width: 100%;
}

#local-keyvisual {
  position: relative;
  background-image: url(../images/common/bg.png);
  background-position: left top;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    margin-bottom: 30px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-size: 3em;
  font-weight: 700;
  padding: 150px 0;
  z-index: 1;
  position: relative;
  text-align: left;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.5);
  text-align: center;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  #local-keyvisual h1 {
    padding: 100px 0;
    font-size: 2.5em;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    padding: 50px 0;
    font-size: 1.7em;
  }
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: left top;
}

.pan1 {
  margin: 20px 0;
}

@media only screen and (max-width: 640px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.mean-container .mean-bar {
  position: fixed !important;
  background: none;
  background-image: linear-gradient(1deg, #2989cc 0%, #0049ac 100%) !important;
  background-image: -moz-linear-gradient(0deg, #2989cc 0%, #0049ac 100%) !important;
  background-image: -webkit-linear-gradient(0deg, #2989cc 0%, #0049ac 100%) !important;
  background-image: -ms-linear-gradient(0deg, #2989cc 0%, #0049ac 100%) !important;
}

.mean-container .mean-nav {
  background: none;
  background-image: linear-gradient(1deg, #2989cc 0%, #0049ac 100%) !important;
  background-image: -moz-linear-gradient(0deg, #2989cc 0%, #0049ac 100%) !important;
  background-image: -webkit-linear-gradient(0deg, #2989cc 0%, #0049ac 100%) !important;
  background-image: -ms-linear-gradient(0deg, #2989cc 0%, #0049ac 100%) !important;
}

.mean-container .mean-nav ul li a {
  border-top: 1px solid #fff !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  /*background-color: #e9832f !important;*/
}

.subpage-title h1 {
  height: 280px;
  text-shadow: 0px 0px 100px white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  font-size: 4.8rem;
  color: #fff;
  line-height: 1.2;
  letter-spacing: 0.8rem;
}

.exportH1 {
  background-repeat: no-repeat;
  background-position: center center;
}

.subpage-title h2 {
  font-size: 3.2rem;
  color: #1971c0;
  letter-spacing: 0.2rem;
  border-bottom: 2px solid #1971c0;
  font-weight: bold;
  line-height: 1.4;
}

.subpage-title h2:first-letter {
  color: #164c7c;
}

.subpage-title h2 span {
  line-height: 0.8rem;
  display: block;
}

.subpage-title h3 {
  display: inline-block;
  background: #1971c0;
  color: #fff;
  padding: 0px 1.6rem;
  font-size: 2rem;
  line-height: 1.6;
  letter-spacing: 0.2rem;
}

#hed_info {
  height: 100%;
  display: flex;
  align-items: stretch;
}

#hed_info .tel span, #hed_info .tel a {
  font-size: 2.8rem;
}

#hed_info .mail {
  margin-left: 2rem;
}

#hed_info > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flxR .tel i {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .flxL {
    text-align-last: left;
    width: 50% !important;
  }
  .flxR {
    width: 50% !important;
    position: relative !important;
  }
  .flxR .tel span, .flxR .tel a {
    font-size: 2.4rem !important;
  }
}

@media only screen and (max-width: 768px) {
  #global_header {
    padding-top: 48px;
  }
  #global_header #header {
    height: 64px !important;
  }
  #global_header #header .inner {
    padding: 0 !important;
    display: flex !important;
  }
  .flxR .tel {
    width: 64px;
    background: #137500;
  }
  .flxR .tel i {
    display: block;
    font-size: 2.8rem;
    color: #fff;
  }
  .flxR .tel span.for-pc {
    display: none;
  }
  .flxR .mail {
    width: 64px !important;
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 640px) {
  .flxL {
    width: 100% !important;
    padding-right: 1.2rem;
  }
  .flxR {
    width: auto !important;
  }
  .flxR .tel {
    width: 64px;
    background: #137500;
  }
  .flxR .tel i {
    display: block;
    font-size: 2.8rem;
    color: #fff;
  }
  .flxR .tel span.for-pc {
    display: none;
  }
  .flxR .mail {
    width: 64px !important;
    margin-left: 0px !important;
  }
}

/*====================@footer=========================*/
#copy {
  background: #00519a;
  padding: 8px 0;
  color: #555;
  font-weight: 400;
}

#copy div {
  color: #fff;
}

#copy a {
  color: #fff;
  font-weight: 400;
}

footer#global_footer {
  background: #1971c0;
  padding-top: 5rem;
}

.footer-inner {
  max-width: 1000px;
  margin: 0 auto;
}

.footer-inner > .fww div:first-child {
  -ms-flex-item-align: center;
  align-self: center;
}

.footer-inner address {
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer-inner address span {
  color: #fff;
  display: block;
}

.footer-inner > div.flex-temp01 {
  max-width: 1000px;
  margin: 0 auto;
}

.footer-nav {
  margin-top: 40px;
  margin-bottom: 40px;
}

.footer-nav ul {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.footer-nav ul li {
  width: 25%;
}

.footer-nav a {
  color: #fff;
  font-size: 1.6rem;
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media only screen and (max-width: 768px) {
  .footer-nav ul {
    flex-wrap: wrap;
    justify-content: unset;
  }
  .footer-nav ul li {
    width: 32%;
  }
}

@media only screen and (max-width: 640px) {
  .footer-nav {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .footer-nav ul {
    width: 50%;
  }
  .footer-nav ul li {
    line-height: 2;
    width: 50%;
  }
}

address li {
  text-align: left;
}

address ul li:last-child a {
  color: #fff;
  background: #ee8c00;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 240px;
}

@media only screen and (max-width: 640px) {
  .footer-inner .flex-temp01 {
    flex-wrap: wrap;
  }
  .footer-inner .flex-temp01 > div {
    width: 100%;
  }
}

.footer-info {
  display: flex;
  justify-content: center;
}

.footer-info > div {
  margin-left: 1rem;
  margin-right: 1rem;
}

.footer-info .f-tel {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.footer-info .f-tel a, .footer-info .f-tel span {
  color: #fff !important;
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media only screen and (max-width: 640px) {
  .footer-info {
    flex-wrap: wrap;
  }
  .footer-info > div {
    width: 100%;
  }
}

#pagetop {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 10;
}

@media screen and (max-width: 768px) {
  #pagetop {
    bottom: 20px;
    right: -10px;
  }
  #pagetop img {
    width: 60% !important;
    height: auto;
  }
}

.midashi {
  margin-bottom: 30px;
}

@media only screen and (max-width: 640px) {
  .midashi {
    margin-bottom: 20px;
  }
}

.midashi h2 {
  font-size: 35px;
  font-weight: 700;
  color: #5d310f;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .midashi h2 {
    font-size: 25px;
  }
}

.midashi h2:before {
  vertical-align: middle;
  content: url(../images/common/icon_1.png);
  margin-right: 10px;
}

@media only screen and (max-width: 640px) {
  .midashi h2:before {
    display: none;
  }
}

.midashi h2:after {
  vertical-align: middle;
  content: url(../images/common/icon_1.png);
  margin-left: 10px;
}

@media only screen and (max-width: 640px) {
  .midashi h2:after {
    display: none;
  }
}

.midashi2 {
  margin-top: 100px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  .midashi2 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi2 {
    margin-bottom: 20px;
  }
}

.midashi2 h2 {
  font-size: 35px;
  font-weight: 700;
  color: #5d310f;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

.midashi3 {
  margin: 0 auto 50px;
  background: #a5dc4f;
  border-radius: 30px;
  padding: 10px;
  max-width: 35%;
}

@media only screen and (max-width: 1000px) {
  .midashi3 {
    max-width: 50%;
  }
}

@media only screen and (max-width: 640px) {
  .midashi3 {
    max-width: 100%;
    margin: 0 auto 30px;
  }
}

.midashi3 h3 {
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .midashi3 h3 {
    font-size: 18px;
  }
}

.midashi4 {
  background: #a5dc4f;
  margin-bottom: 100px;
}

@media only screen and (max-width: 768px) {
  .midashi4 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi4 {
    margin-bottom: 30px;
  }
}

.midashi4 h2 {
  padding: 50px;
  font-size: 35px;
  font-weight: 700;
  color: #5d310f;
  text-align: center;
  line-height: 2em;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  .midashi4 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi4 h2 {
    padding: 20px;
  }
}

.midashi4 h2 span {
  font-size: 25px;
}

@media only screen and (max-width: 768px) {
  .midashi4 h2 span {
    font-size: 18px;
  }
}

.midashi4 h2:before {
  z-index: 0;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  background: url(../images/common/left.png) left center no-repeat;
}

@media only screen and (max-width: 768px) {
  .midashi4 h2:before {
    background-size: 20%;
  }
}

@media only screen and (max-width: 640px) {
  .midashi4 h2:before {
    display: none;
  }
}

.midashi4 h2:after {
  z-index: 0;
  display: block;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  background: url(../images/common/right.png) right center no-repeat;
}

@media only screen and (max-width: 768px) {
  .midashi4 h2:after {
    background-size: 20%;
  }
}

@media only screen and (max-width: 640px) {
  .midashi4 h2:after {
    display: none;
  }
}

.dogslist {
  overflow: hidden;
  display: none;
}

.dogslist .dogsbox {
  padding: 10px;
  font-size: 14px;
}

.dogslist .dogsbox td {
  border: 0 !important;
  padding: 0 !important;
}

.dogslist .dogsbox img {
  margin-bottom: 20px;
  width: 100% !important;
  height: 12vw !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 1000px) {
  .dogslist .dogsbox img {
    height: 18vw !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .dogslist .dogsbox img {
    height: 30vw !important;
  }
}

@media only screen and (max-width: 640px) {
  .dogslist .dogsbox img {
    height: 50vw !important;
  }
}

.dogslist .dogsbox .name div {
  padding-bottom: 10px;
}

.dogslist .dogsbox .name div:before {
  content: "性別：";
  color: #333;
}

.dogslist .dogsbox .メス div {
  font-weight: bold;
  color: #f87199;
}

.dogslist .dogsbox .オス div {
  font-weight: bold;
  color: #3fcae4;
}

.dogslist .dogsbox .dogname div {
  font-weight: 700;
  padding-bottom: 10px;
}

.dogslist .dogsbox .dogname div:before {
  content: "犬種：";
  color: #333;
}

.dogslist .dogsbox a {
  border-radius: 30px;
  color: #fff;
  display: block;
  margin: 10px 0 0;
  width: 100%;
  padding: 4px 20px;
  background: #3e3233;
  text-align: center;
}

.cont_1 {
  margin-bottom: 100px;
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 1000px) {
  .cont_1 .inner {
    padding: 0 20px;
    background: rgba(255, 255, 255, 0.5);
  }
}

@media only screen and (max-width: 768px) {
  .cont_1 {
    margin-bottom: 50px;
  }
  .cont_1 .inner {
    padding: 0;
  }
}

.cont_1:before {
  z-index: -1;
  display: block;
  top: 0;
  left: 3%;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  background: url(../images/common/img_5.jpg) left top no-repeat;
}

@media only screen and (max-width: 640px) {
  .cont_1:before {
    display: none;
  }
}

.cont_1:after {
  z-index: -1;
  display: block;
  bottom: 0;
  right: 3%;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  background: url(../images/common/img_6.jpg) right bottom no-repeat;
}

@media only screen and (max-width: 640px) {
  .cont_1:after {
    display: none;
  }
}

.cont_2 {
  margin-top: 100px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 1000px) {
  .cont_2 {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 768px) {
  .cont_2 {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0;
  }
}

.cont_2 .box {
  background: #222;
}

.cont_2 .box .midashi5 {
  padding: 20px 0;
  background: url(../images/common/border.png) repeat-x bottom left;
  margin-bottom: 10px;
}

.cont_2 .box .midashi5 h2 {
  font-size: 28px;
  color: #5d310f;
  font-weight: 700;
  text-align: left;
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  .cont_2 .box .midashi5 h2 {
    font-size: 20px;
  }
}

.cont_2 .box .midashi5 h2:before {
  margin-right: 20px;
  vertical-align: middle;
}

.cont_2 .box .txt {
  padding: 20px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 700;
  color: #351e0a;
}

.cont_2 .box .txt div {
  line-height: 3em;
}

.cont_2 .box .txt b {
  font-size: 25px;
}

@media only screen and (max-width: 1000px) {
  .cont_2 .box .txt b {
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .cont_2 .box .txt b {
    font-size: 18px;
  }
}

.cont_2 .box:nth-child(1) .midashi5 h2:before {
  content: url(../images/common/icon_2.png);
}

.cont_2 .box:nth-child(1) .txt {
  background: #ffe1e8;
}

.cont_2 .box:nth-child(1) .txt b {
  color: #e77a92;
}

.cont_2 .box:nth-child(2) {
  margin-top: 30px;
}

.cont_2 .box:nth-child(2) .midashi5 h2:before {
  content: url(../images/common/icon_3.png);
}

.cont_2 .box:nth-child(2) .txt {
  background: #ffedb3;
}

.cont_2 .box:nth-child(2) .txt b {
  color: #fca103;
}

.cont_2 .box:nth-child(3) {
  margin-top: 30px;
}

.cont_2 .box:nth-child(3) .midashi5 h2:before {
  content: url(../images/common/icon_4.png);
}

.cont_2 .box:nth-child(3) .txt {
  background: #d6ff97;
}

.cont_2 .box:nth-child(3) .txt b {
  color: #7dbe18;
}

.cont_2 .box:nth-child(4) {
  margin-top: 30px;
}

.cont_2 .box:nth-child(4) .midashi5 h2:before {
  content: url(../images/common/icon_5.png);
}

.cont_2 .box:nth-child(4) .txt {
  background: #c0fdfb;
}

.cont_2 .box:nth-child(4) .txt b {
  color: #20c8c2;
}

.bnr {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1000px) {
  .bnr {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 768px) {
  .bnr {
    padding: 0;
  }
}

.bnr .box {
  width: 21.7741935483871%;
}

@media only screen and (max-width: 768px) {
  .bnr .box {
    width: 49%;
    margin-bottom: 20px;
  }
}

.bnr .box img {
  width: 100% !important;
}

.cont_3 {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1000px) {
  .cont_3 {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 768px) {
  .cont_3 {
    display: block;
    margin-top: 50px;
    padding: 0;
  }
}

.cont_3 .box {
  width: 48%;
}

@media only screen and (max-width: 768px) {
  .cont_3 .box {
    width: 100%;
    margin-bottom: 30px;
  }
}

.cont_3 .box .midashi6 {
  border-bottom: 1px solid #fcadbd;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.cont_3 .box .midashi6 h2 {
  font-size: 20px;
  font-weight: 700;
  color: #5d310f;
  text-align: center;
}

.cont_3 .box .midashi6 h2:before {
  vertical-align: middle;
  content: url(../images/common/icon_6.png);
  margin-right: 10px;
}

.cont_3 .box .midashi6 h2:after {
  vertical-align: middle;
  content: url(../images/common/icon_6.png);
  margin-left: 10px;
}

/*blog*/
.blog2 .blog_photo, .blog2 .detail {
  display: none;
}

.blog2 .blog_date {
  float: left;
  width: 15%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  background: #2c2e30;
  padding: 5px 10px;
  color: #fff !important;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .blog2 .blog_date {
    width: 25%;
  }
}

@media only screen and (max-width: 640px) {
  .blog2 .blog_date {
    width: 100%;
    float: none;
  }
}

.blog2 h3 {
  color: #000;
  float: right;
  width: 84%;
  margin-bottom: 0;
  margin-top: 5px;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .blog2 h3 {
    width: 70%;
  }
}

@media only screen and (max-width: 640px) {
  .blog2 h3 {
    width: 100%;
    float: none;
    text-align: center;
  }
}

.blog2 h3 a {
  font-size: 13px;
  color: #333;
  text-decoration: none;
}

.blog2 h3 a:hover {
  color: #000;
  text-decoration: none;
  opacity: 1;
}

.blog_list h3 span {
  font-weight: 500;
}

.blog2 .blog_text {
  width: 100% !important;
  padding: 0 !important;
}

.blog2 .blog_list {
  border: 0;
}

.blog2 .blog_list > div {
  padding-bottom: 25px;
  margin-bottom: 25px;
}

div.blog_list > div:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.inst {
  text-align: center;
}

.inst .sns_list {
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.inst .sns_list > div {
  width: 30.79526%;
  margin-left: 2.53807%;
  margin-bottom: 2.53807%;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .inst .sns_list > div {
    width: 30.79526%;
    margin-left: 2.53807%;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .inst .sns_list > div {
    width: 49% !important;
    margin-left: 0 !important;
  }
}

.inst .sns_list > div img {
  width: 100% !important;
  height: 10vw !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .inst .sns_list > div img {
    height: 25vw !important;
  }
}

@media only screen and (max-width: 640px) {
  .inst .sns_list > div img {
    height: 38vw !important;
  }
}

.inst .sns_text {
  display: none;
}

.inst .sns_photo {
  width: 100% !important;
  padding: 0 !important;
}

.img100 {
  margin: 50px auto 0;
}

.img100 img {
  width: 100% !important;
}

.fb {
  text-align: center;
}

.fb iframe {
  width: 100% !important;
}

h2 {
  font-size: 3.6rem;
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.main-img {
  height: 600px;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .main-img {
    height: 320px;
  }
  .main-img h1 {
    font-size: 2.4rem !important;
  }
  .main-img h1 span {
    font-size: 3rem !important;
  }
}

@media only screen and (max-width: 640px) {
  .main-img {
    height: 240px !important;
  }
  .main-img h1 {
    font-size: 2.4rem !important;
  }
  .main-img h1 span {
    font-size: 3rem !important;
  }
}

.main-img h1 {
  color: #fff;
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 4rem;
}

.main-img h1 span {
  font-size: 5rem;
}

.home-img01 {
  transform: translate(-100px, 50px);
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .home-img01 img {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home-img01 {
    transform: translate(-10px, 20px) !important;
  }
}

.main-img.sub-page h1 {
  font-size: 4rem !important;
}

.home-con1 {
  position: relative;
}

.home-con1 > div {
  position: relative;
  color: #fff;
}

.home-con1 h2 {
  border-bottom: dotted 1px #fff;
  padding-bottom: 18px;
}

.home-con1:before {
  content: "about";
  display: block;
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 130px;
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  right: 0;
  bottom: 0;
  letter-spacing: 0;
  line-height: 1;
}

.arrow-title {
  position: relative;
}

.arrow-title h2 {
  color: #fff;
}

.arrow-title:after {
  position: absolute;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 56px 54px 0 54px;
  border-color: #1468bb transparent transparent transparent;
  right: 0;
  left: 0;
  margin: 0 auto;
  top: 100%;
  visibility: visible;
}

.home-con2 {
  display: flex;
}

.home-con2 > div {
  position: relative;
}

.home-con2 > div > div {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  width: 100%;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  font-size: 2rem;
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media only screen and (max-width: 768px) {
  .home-con2 > div {
    height: 240px;
    overflow: hidden;
  }
}

.home-con2 .imgL {
  margin: 0 !important;
}

@media only screen and (max-width: 640px) {
  .home-con2 .imgL {
    max-width: 100% !important;
  }
}

.home-con2 a {
  width: 100% !important;
}

.home-con2 a img {
  width: 100% !important;
}

@media only screen and (max-width: 640px) {
  .home-con2 {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.home-con2 .imgR, .home-con2 .imgL, .home-con2 .imgL {
  max-width: 100% !important;
}

.home-con3 {
  display: flex;
  flex-wrap: wrap;
}

.home-con3 .box {
  width: 33.333%;
  background-size: cover;
}

@media only screen and (max-width: 640px) {
  .home-con3 .box {
    width: 100%;
    max-width: 100% !important;
  }
}

.home-con3 .box a {
  display: block;
  padding: 16.6% 15%;
  color: #fff;
}

@media only screen and (max-width: 1000px) {
  .home-con3 .box a {
    padding: 16.6% 9%;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .home-con3 .box a {
    padding: 16.6% 6%;
  }
}

.home-con3 .box a:hover {
  background: rgba(255, 255, 255, 0.3);
}

.home-con3 .box a article {
  text-align: center;
}

.home-con3 .box a article > div {
  display: inline-block;
  padding: 1.9% 11.4%;
  border: 1px solid #fff;
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 18px;
  letter-spacing: 1px;
}

.home-con3 .box h2 {
  border-bottom: 1px solid #fff;
  margin-bottom: 45px;
  padding-bottom: 20px;
  font-size: 36px;
  line-height: 1;
}

@media only screen and (max-width: 1600px) {
  .home-con3 .box h2 {
    font-size: 2.25vw;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .home-con3 .box h2 {
    margin-bottom: 25px;
    font-size: 2.6vw;
  }
}

@media only screen and (max-width: 640px) {
  .home-con3 .box h2 {
    font-size: 22px;
  }
}

.home-con3 .box h2 .en {
  display: block;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
}

.home-con4 {
  display: flex;
}

.home-con4 > .news-wrap:first-child {
  background: url("../images/common/br01.png");
}

.home-con4 > .news-wrap:last-child {
  background: #1971c0;
}

.home-con4 > .news-wrap:last-child h2, .home-con4 > .news-wrap:last-child div {
  color: #fff;
}

.home-con4 > .news-wrap {
  width: 100%;
}

.home-con4 > .news-wrap h2 {
  font-weight: 400;
  font-size: 3rem;
  margin-bottom: 50px;
  padding-bottom: 2rem;
}

.home-con4 > .news-wrap .news {
  background: #fff;
  color: #222;
  padding: 2rem;
  height: 340px;
  overflow-y: scroll;
}

.home-con4 > .news-wrap .news dl {
  padding: 0.8rem 0;
  border-bottom: dotted 1px #999;
}

.home-con4 > .news-wrap .news dl dt, .home-con4 > .news-wrap .news dl dd {
  padding: 0 !important;
}

.home-con4 > .news-wrap .news dl dt {
  color: #ee8c00;
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  border: none !important;
  background: none !important;
  font-size: 1.8rem;
  min-width: 180px;
}

.home-con4 > .news-wrap .news dl dd {
  border: none !important;
  background: none !important;
  font-size: 1.6rem;
  font-weight: 400;
  color: #555;
}

@media only screen and (max-width: 640px) {
  .home-con4 > .news-wrap .news dl {
    flex-wrap: wrap;
  }
  .home-con4 > .news-wrap .news dl dt, .home-con4 > .news-wrap .news dl dd {
    width: 100%;
  }
}

.home-con4 > .news-wrap > div {
  max-width: 64%;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .home-con4 > .news-wrap > div {
    max-width: 88% !important;
  }
}

.home-con4 > .news-wrap > div:first-child h2 {
  border-bottom: 1px solid #1971c0;
  color: #1971c0;
}

.home-con4 .news-wrap:last-child h2 {
  border-bottom: 1px solid #fff !important;
  color: #fff !important;
}

@media only screen and (max-width: 768px) {
  .home-con4 {
    flex-wrap: wrap;
  }
  .home-con4 > div {
    width: 100% !important;
  }
}

.お知らせ {
  display: inline-block;
  padding: 0px 4px;
  font-size: 13px;
  margin-right: 24px;
  color: #fff;
  font-weight: bold;
  background: #1971c0;
  width: 110px;
  text-align: center;
  letter-spacing: 0.1em;
}

.プレリリース {
  display: inline-block;
  padding: 0px 4px;
  font-size: 13px;
  margin-right: 24px;
  color: #fff;
  font-weight: bold;
  background: #ee8c00;
  width: 110px;
  text-align: center;
  letter-spacing: 0.1em;
}

.news dl dd.newsTag {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .news dl dd.newsTag {
    justify-content: flex-start;
  }
}

.home-con5 h2 {
  color: #555;
  border-bottom: solid #555 1px;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 1.8rem;
}

.home-con5 .title span {
  color: #555;
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 1.8rem;
}

.home-con5 .link-logo {
  border: 1px solid #999;
  background: #fff;
  padding: 80px 0;
  justify-content: space-around;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .home-con5 .link-logo {
    flex-wrap: wrap;
    padding: 40px 0 20px;
  }
  .home-con5 .link-logo .box {
    padding: 0.8rem;
    width: 33%;
    text-align: center;
    margin-bottom: 2rem;
  }
}

.sub-page.main-img {
  height: 320px;
}

.company-table table tr td:first-child {
  background: #f3f3f3;
}

.company-table table td {
  border: 1px solid #aaa;
}

.pankuzu div {
  text-align: right !important;
}

.product-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.product-side {
  max-width: 23%;
  width: 100% !important;
}

@media only screen and (max-width: 768px) {
  .product-side {
    max-width: 100%;
  }
}

.product-side ul {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.product-side ul li {
  overflow: inherit !important;
  float: none !important;
}

.product-side h3 {
  text-align: center;
  color: #fff;
  font-size: 2.4rem;
}

.product-side .group {
  border-bottom: 1px solid #aaa;
  display: block;
  font-size: 1.8rem;
  color: #164c7c;
  margin: 0.8rem 0;
}

.product-side .group a {
  text-indent: 0;
  color: #164c7c;
}

.product-side a {
  display: block;
  text-indent: 1rem;
}

@media only screen and (max-width: 768px) {
  .product-side {
    display: none;
  }
}

.product-content {
  flex-grow: 1;
  max-width: 73%;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .product-content {
    max-width: 100%;
  }
}

.product-anchor {
  justify-content: space-around;
}

.product-anchor .box {
  width: 32%;
  margin-bottom: 0.8rem;
}

.product-anchor .box a {
  text-align: center;
  transition-duration: 0.5s;
  display: block;
  border: 2px solid #1971c0;
  padding: 0.4rem;
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 1.8rem;
}

.product-anchor .box a:hover {
  background: #1971c0;
  color: #fff;
  opacity: 1;
}

@media only screen and (max-width: 640px) {
  .product-anchor .box {
    width: 100%;
    margin-bottom: 1.6rem;
  }
}

.product-links {
  justify-content: flex-start;
  flex-wrap: wrap;
}

.product-links > div {
  width: 33.333%;
  border: #fff solid 4px;
}

.product-links > div a {
  display: block;
  height: 100%;
}

.product-links > div img {
  width: 100%;
  height: 100% !important;
}

.product-links > div > div {
  font-size: 1.6rem;
}

@media only screen and (max-width: 768px) {
  .product-links > div {
    height: 28vw !important;
    width: 28vw !important;
  }
}

@media only screen and (max-width: 640px) {
  .product-links > div {
    height: 45vw !important;
    width: 45vw !important;
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 640px) {
  .product-links {
    justify-content: space-between;
  }
}

.equip-table table tr td {
  font-size: 1.6rem;
}

.number {
  display: inline !important;
}

.product-table td {
  padding: 3px !important;
  font-size: 1.6rem;
}

.product-table .bg-gra02 {
  background: #b3bead;
  /* Old browsers */
  background: -moz-linear-gradient(top, #b3bead 0%, #cecece 0%, #f2f2f2 47%, #f7f7f7 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #b3bead 0%, #cecece 0%, #f2f2f2 47%, #f7f7f7 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #b3bead 0%, #cecece 0%, #f2f2f2 47%, #f7f7f7 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3bead', endColorstr='#f7f7f7',GradientType=0 );
  /* IE6-9 */
}

.product-table tr:nth-child(n+2) td {
  font-size: 1.4rem;
}

.item-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.item-flex .item-table {
  width: 50%;
  padding: 0.8rem;
}

.item-flex .item-table article {
  border: 2px solid #aaa !important;
  height: 100%;
  border: 1px solid #AAA;
}

.item-flex .item-table td {
  font-size: 1.4rem;
}

.item-flex .item-table td b {
  font-size: 1.8rem;
  color: #164c7c;
}

@media only screen and (max-width: 768px) {
  .item-flex .item-table {
    width: 100%;
  }
}

.coating-table01 td span {
  color: #1971c0;
  display: inline-block;
  padding-bottom: 4px;
  border-bottom: 1px solid #1971c0;
  width: 100%;
  margin-bottom: 4px;
  font-weight: bold;
}

.fixed-table table {
  table-layout: fixed !important;
}

.banner-table {
  margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .banner-table {
    margin-bottom: 30px;
  }
}

.banner-table td {
  text-align: center;
  padding: 0.8rem !important;
}

.banner-table table {
  border-collapse: separate !important;
  border-spacing: 1.2rem !important;
}

.banner-table .title {
  font-size: 2.4rem;
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #fff;
  line-height: 1.6;
}

.banner-table .text a, .banner-table .text span {
  font-size: 1.4rem;
  color: #222;
}

.banner-table .text > div > span {
  display: block;
}

.banner-table .text > div > a {
  display: block;
}

.banner-table .tel02, .banner-table .mail02 {
  border: 1px solid #aaa !important;
  background: #f3f3f3;
}

.banner-table .sub-title {
  font-size: 2rem !important;
  color: #164c7c !important;
}

.banner-table .btn {
  border: 1px solid #1971c0;
  color: #1971c0 !important;
  padding: 0.8rem 3.2rem;
  display: inline-block;
  margin: 1.2rem auto;
  font-size: 1.6rem !important;
  background: #fff;
}

.anchor {
  margin-top: -80px;
  padding-top: 80px;
}

#pagetop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}

@media screen and (max-width: 468px) {
  #pagetop {
    bottom: 20px;
  }
  #pagetop img {
    width: 60% !important;
    height: auto;
  }
}

.dogswrap {
  margin-left: -2.439024390243902%;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1000px) {
  .dogswrap {
    margin-left: -3.846153846153846%;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .dogswrap {
    margin-left: -3.03030303030303%;
  }
}

@media only screen and (max-width: 640px) {
  .dogswrap {
    margin-left: 0;
    justify-content: space-between;
  }
}

.dogswrap .dogsbox {
  width: 17.5609756097561%;
  margin-left: 2.439024390243902%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1000px) {
  .dogswrap .dogsbox {
    width: 21.15384615384615%;
    margin-left: 3.846153846153846%;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .dogswrap .dogsbox {
    width: 30.3030303030303%;
    margin-left: 3.03030303030303%;
  }
}

@media only screen and (max-width: 640px) {
  .dogswrap .dogsbox {
    width: 100%;
    margin-left: 0;
  }
}

.dogswrap .dogsbox td {
  border: 0 !important;
  padding: 0 !important;
}

.dogswrap .dogsbox img {
  margin-bottom: 10px;
  width: 100% !important;
  height: 12vw !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 1000px) {
  .dogswrap .dogsbox img {
    height: 20vw !important;
  }
}

@media only screen and (max-width: 640px) {
  .dogswrap .dogsbox img {
    height: 70vw !important;
  }
}

.dogswrap .dogsbox .name div {
  padding-bottom: 10px;
}

.dogswrap .dogsbox .メス div {
  font-weight: bold;
  color: #f87199;
}

.dogswrap .dogsbox .オス div {
  font-weight: bold;
  color: #3fcae4;
}

.dogswrap .dogsbox a {
  border-radius: 30px;
  color: #fff;
  display: block;
  margin: 15px 0 0;
  width: 100%;
  padding: 4px 20px;
  background: #3e3233;
  text-align: center;
}

.dogsimg {
  justify-content: center;
  align-items: center;
}

.dogsimg .box {
  width: 48%;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  .dogsimg .box {
    width: 100%;
  }
  .dogsimg .box img {
    width: 100% !important;
  }
}

.dogstxt {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 50px;
}

@media only screen and (max-width: 640px) {
  .dogstxt {
    display: block;
  }
}

.dogstxt .form_wrap {
  width: 50%;
}

@media only screen and (max-width: 640px) {
  .dogstxt .form_wrap {
    width: 100%;
  }
}

.dogstxt dl, .dogstxt dt, .dogstxt dd {
  border: 0 !important;
  align-items: center !important;
}

@media only screen and (max-width: 640px) {
  .dogstxt dl, .dogstxt dt, .dogstxt dd {
    width: 100% !important;
    display: block !important;
    text-align: center;
  }
}

@media only screen and (max-width: 640px) {
  .dogstxt dd {
    border: 1px solid #ccc !important;
  }
}

.dogstxt dl {
  border-top: 1px solid #eee !important;
}

@media only screen and (max-width: 640px) {
  .dogstxt dl {
    border: 0 !important;
  }
}

.dogstxt dl:last-child {
  border-bottom: 1px solid #eee !important;
}

.sub_midashi {
  margin-bottom: 30px;
}

.sub_midashi h2 {
  font-size: 35px;
  font-weight: 700;
  color: #5d310f;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .sub_midashi h2 {
    font-size: 25px;
  }
}

.sub_midashi h2:before {
  vertical-align: middle;
  content: url(../images/common/icon_1.png);
  margin-right: 10px;
}

@media only screen and (max-width: 640px) {
  .sub_midashi h2:before {
    display: none;
  }
}

.sub_midashi h2:after {
  vertical-align: middle;
  content: url(../images/common/icon_1.png);
  margin-left: 10px;
}

@media only screen and (max-width: 640px) {
  .sub_midashi h2:after {
    display: none;
  }
}

.comment {
  padding: 50px;
  border: 2px #ffbf00 solid;
  margin-top: 100px;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .comment {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 640px) {
  .comment {
    margin-top: 30px;
    padding: 20px;
  }
}

@media only screen and (max-width: 640px) {
  iframe {
    height: 300px !important;
  }
}

.flow td {
  border: 0;
  border-radius: 20px;
}

.sub_midashi2 {
  margin-top: 50px;
  margin-bottom: 30px;
  background: #fefff2;
}

.sub_midashi2 h2 {
  font-size: 25px;
  font-weight: 700;
  color: #5d310f;
  border-left: 5px solid #9cd521;
  padding: 15px 20px;
  line-height: 1.2;
  color: #FF940B;
}

.cont_4 {
  margin-top: 100px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 768px) {
  .cont_4 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.cont_4 .box .midashi5 {
  padding: 20px 0;
  background: url(../images/common/border.png) repeat-x bottom left;
  margin-bottom: 10px;
}

.cont_4 .box .midashi5 h2 {
  font-size: 28px;
  color: #5d310f;
  font-weight: 700;
  text-align: left;
  vertical-align: middle;
}

.cont_4 .box .midashi5 h2:before {
  margin-right: 20px;
  vertical-align: middle;
}

.cont_4 .box .txt {
  padding: 20px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 700;
  color: #351e0a;
}

.cont_4 .box .txt div {
  line-height: 3em;
}

.cont_4 .box .txt b {
  font-size: 25px;
}

.cont_4 .box:nth-child(1) .midashi5 h2:before {
  content: url(../images/common/icon_7.png);
}

.cont_4 .box:nth-child(1) .txt {
  background: #ffe1e8;
}

.cont_4 .box:nth-child(1) .txt b {
  color: #e77a92;
}

.cont_4 .box:nth-child(2) {
  margin-top: 30px;
}

.cont_4 .box:nth-child(2) .midashi5 h2:before {
  content: url(../images/common/icon_8.png);
}

.cont_4 .box:nth-child(2) .txt {
  background: #ffedb3;
}

.cont_4 .box:nth-child(2) .txt b {
  color: #fca103;
}

.cont_4 .box:nth-child(3) {
  margin-top: 30px;
}

.cont_4 .box:nth-child(3) .midashi5 h2:before {
  content: url(../images/common/icon_9.png);
}

.cont_4 .box:nth-child(3) .txt {
  background: #d6ff97;
}

.cont_4 .box:nth-child(3) .txt b {
  color: #7dbe18;
}

.cont_4 .box:nth-child(4) {
  margin-top: 30px;
}

.cont_4 .box:nth-child(4) .midashi5 h2:before {
  content: url(../images/common/icon_10.png);
}

.cont_4 .box:nth-child(4) .txt {
  background: #c0fdfb;
}

.cont_4 .box:nth-child(4) .txt b {
  color: #20c8c2;
}

.cont_4 .box:nth-child(5) {
  margin-top: 30px;
}

.cont_4 .box:nth-child(5) .midashi5 h2:before {
  content: url(../images/common/icon_11.png);
}

.cont_4 .box:nth-child(5) .txt {
  background: #ffe1e8;
}

.cont_4 .box:nth-child(5) .txt b {
  color: #e77a92;
}

.cont_4 .box:nth-child(6) {
  margin-top: 30px;
}

.cont_4 .box:nth-child(6) .midashi5 h2:before {
  content: url(../images/common/icon_12.png);
}

.cont_4 .box:nth-child(6) .txt {
  background: #ffedb3;
}

.cont_4 .box:nth-child(6) .txt b {
  color: #fca103;
}

.comp dl, .comp dt, .comp dd {
  border: 0 !important;
}

@media only screen and (max-width: 640px) {
  .comp dl, .comp dt, .comp dd {
    width: 100% !important;
    display: block !important;
    text-align: center;
  }
}

@media only screen and (max-width: 640px) {
  .comp dd {
    border: 1px solid #ccc !important;
  }
}

.comp dl {
  flex-wrap: wrap;
  border-top: 1px solid #eee !important;
}

@media only screen and (max-width: 640px) {
  .comp dl {
    border: 0 !important;
  }
}

.comp dl:last-child {
  border-bottom: 1px solid #eee !important;
}

.comp dl dt {
  align-items: center !important;
}

.q {
  padding: 20px;
  border-top: 1px solid #cdcdcd;
  cursor: pointer;
  color: #004225;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .q {
    padding: 10px 0;
  }
}

.q:before {
  font-family: "Font Awesome 5 pro";
  content: "\f107";
  position: absolute;
  right: 30px;
  top: 30%;
}

@media only screen and (max-width: 640px) {
  .q:before {
    display: none;
  }
}

.q div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.q b {
  font-size: 40px;
  margin-right: 10px;
  vertical-align: sub;
}

@media only screen and (max-width: 640px) {
  .q b {
    font-size: 20px;
  }
}

.a {
  padding: 20px;
  border-top: 1px dotted #cdcdcd;
  line-height: 1.7em;
}

@media only screen and (max-width: 640px) {
  .a {
    padding: 10px 0;
  }
}

.a div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.a b {
  font-size: 40px;
  margin-right: 10px;
  vertical-align: sub;
  color: #b3a346;
}

@media only screen and (max-width: 640px) {
  .a b {
    font-size: 20px;
  }
}

.sub_midashi3 {
  margin-bottom: 50px;
}

@media only screen and (max-width: 640px) {
  .sub_midashi3 {
    margin-bottom: 0;
    margin-top: 50px;
  }
}

.sub_midashi3 h3 {
  background: #dbffa0;
  border-radius: 30px;
  padding: 10px;
  max-width: 35%;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  text-align: center !important;
}

@media only screen and (max-width: 640px) {
  .sub_midashi3 h3 {
    max-width: 100%;
  }
}

.center {
  margin-top: 20px;
  text-align: center;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}
